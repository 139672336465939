<template>
  <section>
      <div class="content-header">
        <h2>Five Factor Model of Resilience  </h2>
      </div>
      <div class="content-wrapper">
        <p>After years of study in the field of positive psychology, Dr. Brecher has developed the <strong class="bold">Five Factor Model of Resilience</strong>. </p>
        <p>It puts mindfulness at the centre of resilience, with gratitude, optimism, self-compassion and grit each playing a role in how people bounce back from adversity. </p>
        <img src="@/assets/img/_illustrations/personal-learn-5.svg" width="400px" height="400px" class="img-fluid d-block mx-auto" alt="A matrix depicting the five factor model of resilience" >
        <p class="text-center pt-2">The Five Factor Model of Resilience </p>
        <p>The <a href="https://www.ryerson.ca/content/dam/thriveru/resources/ThriveRU-Facillitators-Web-08312017.pdf" target="_blank">Cultivate Your Happiness Weekly Workbook (Facilitator's Manual)</a> goes into greater detail about how each factor is interconnected and provides insights into how each of us can cultivate resilience.</p>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
